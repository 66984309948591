<template>
    <div class="p-0 m-0">
        <Sidebar />

        <div>
            <StoreImage :store_detail="detailStore" />
        </div>
        <div class="ps-2">
            <Username />
        </div>
        <div style="margin-bottom: 7em">
            <HeaderNavigasi :link="links" />

            <div v-if="isLoading">
                <v-container>
                    <v-row>
                        <v-col
                        v-for="i in 10"
                        :key="i"
                        cols="4"
                        lg="2"
                        md="2"
                        sm="2"
                        active="1"
                        style="padding: 5px 4px 0px 4px;"
                        >
                        <Skeleton />
                        </v-col>
                    </v-row>
                </v-container>
            </div>

            <div v-else>
                <div v-if="itemRecommendByStore.length == 0" class="d-flex flex-row align-center justify-center mt-12">
                    <h3 style="color: grey">
                        {{ $t("message.no-recommend-product") }}
                    </h3>
                </div>

                <v-container>
                    <v-row style="padding: 0px"> 
                        <v-col
                        v-for="itemRecomend in itemRecommendByStore"
                        :key="itemRecomend.id"
                        cols="4"
                        lg="2"
                        md="2"
                        sm="2"
                        active="1"
                        style="padding: 5px 4px 10px 4px;"
                        >
                        <Product :data="itemRecomend" />
                        </v-col>
                    </v-row>

                    <div class="text-start">
                        <v-pagination
                        v-if="!isLength"
                        color="#ff0090"
                        v-model="pageRecomendByStore"
                        :length="itemRecommendByStoreHeader.last_page"
                        :total-visible="5"
                        class="mt-6"
                        @input="nextRecomendByStore"
                        ></v-pagination>
                    </div>
                </v-container>
                <div class="d-flex justify-end">
                    <v-btn
                    color="transparent"
                    class="me-2 black--text"
                    depressed
                    @click="$router.go(-1)"
                    >
                    <v-icon left dark>
                        mdi-chevron-left
                    </v-icon>
                    {{ $t("message.back") }}
                    </v-btn>
                </div>
            </div>
            <div>
                <CategoryFooter />
            </div>
        </div>
        <Cashier />
    </div>
</template>

<script>
import CategoryFooter from '../../components/CategoryFooter.vue'
import Cashier from '../../components/Cashier.vue'
import Product from '../../components/Product.vue'
import Sidebar from '../../components/Sidebar.vue'
import StoreImage from '../../components/StoreImage.vue'
import User from '../../components/User.vue'
import Skeleton from '../../components/skeleton/Skeleton.vue'
import HeaderNavigasi from '../../components/HeaderNavigasi.vue'
import { mdiMagnify, mdiHeartOutline, mdiCart, mdiStore } from "@mdi/js";
export default {
    name: "ShopRecommendation",
    components: {
        Sidebar,
        StoreImage,
        Username: User,
        HeaderNavigasi,
        Product,
        Skeleton,
        CategoryFooter,
        Cashier
    },
    data() {
        return {
            isLoading: false,
            pageRecomendByStore: 1,
            idStore: ""
        }
    },
    methods: {
        async dispatchStore() {
            let id = this.$router.history.current.params.id
            this.idStore = this.$router.history.current.params.id
            await this.$store.dispatch("shop_module/fetchStore", { id: id })
        },
        async dispatchItemRecommendByStore() {
            this.isLoading = true
            let id = this.$router.history.current.params.id
            await this.$store.dispatch("itemRecomend_module/itemRecomendByStore", { id: id, page: this.pageRecomendByStore })
            this.isLoading = false
        },
        detailProduct(slug) {
            this.$router.push("/items/" + slug);
        },
        nextRecomendByStore() {
            let id = this.$router.history.current.params.id
            this.$store.dispatch("itemRecomend_module/itemRecomendByStore", {
                id: id,
                page: this.pageRecomendByStore,
            })
        }
    },
    computed: {
        isLength() {
            return this.$store.state.itemRecomend_module.is_length
        },  
        detailStore() {
            return this.$store.state.shop_module.store;
        },
        itemRecommendByStore() {
            return this.$store.state.itemRecomend_module.itemRecommendByStore
        },
        itemRecommendByStoreHeader() {
            return this.$store.state.itemRecomend_module.itemRecommendByStoreHeader;
        },
        links() {
            return [
                {
                    text: this.$t("message.route-search"),
                    route: `/store/${this.idStore}/category`,
                    icon: mdiMagnify,
                },
                {
                    text: this.$t("message.route-favorite"),
                    route: `/store/${this.idStore}/wishlist`,
                    icon: mdiHeartOutline,
                },
                {
                    text: this.$t("message.route-history"),
                    route: `/store/${this.idStore}/purchase-history`,
                    icon: mdiCart,
                },
                {
                    text: this.$t("message.route-store"),
                    route: "/store",
                    icon: mdiStore,
                },
                ];
            },
    },
    created() {
        this.dispatchStore()
        this.dispatchItemRecommendByStore()
    }
}
</script>

<style>

</style>